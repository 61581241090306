import React from "react"

import PropTypes from "prop-types"
import Img from "gatsby-image"
import { uniq } from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

import {
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  Typography,
} from "@material-ui/core"

import { LocaleContext } from "../../contexts/LocaleContext"
import { localizedSlugPrefix } from "../../gatsby/nodeHelpers"

import BackgroundShadow from "../../img/shadow_box.png"

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 220,
    display: "flex",
  },

  image: {
    height: "100%",
  },
  media: {
    position: "relative",
    height: "100%",
  },

  shortInfo: {
    fontFamily: "Montserrat",
    position: "absolute",
    bottom: 0,
    color: theme.palette.common.white,
    fontSize: 18,
    fontWeight: 700,
    background: `url(${BackgroundShadow}) repeat-x 0 100%`,
    padding: "5px 10px",
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    textTransform: "uppercase",
    display: "block",
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  intro: {},

  summary: {
    marginTop: 10,
    color: "#777",
  },

  summaryBox: {
    padding: "3px 0",
  },

  buttons: {
    height: "100%",
  },
  price: {
    fontSize: 22,
    display: "block",
    textAlign: "center",
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  priceInfo: {
    textAlign: "center",
    display: "block",
    fontSize: 11,
    color: "#999",
  },

  button: {
    background: theme.palette.turistBlue.main,
    fontFamily: "Montserrat",
    fontWeight: 700,
    color: theme.palette.common.white,

    "&:hover": {
      background: theme.palette.common.black,
    },
  },
}))

const TourList = ({ tours, pageSlug }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const classes = useStyles()

  const getLocations = tour => {
    const locations = tour.data.services.map(
      service => service.service_link.document.data.address
    )

    return uniq(locations).join(", ")
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={2}
      >
        {tours.map(tour => (
          <Grid item key={tour.uid}>
            <Card className={classes.card} variant="outlined">
              <Grid container alignItems="stretch">
                <Grid item xs={12} md={4}>
                  <div className={classes.media}>
                    <Link
                      to={`/${localizedSlugPrefix(tour)}/${pageSlug}/${
                        tour.uid
                      }/`}
                    >
                      <Img
                        className={classes.image}
                        alt={tour.data.main_image.alt}
                        fluid={tour.data.main_image.fluid}
                      />
                    </Link>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.shortInfo}
                    >
                      <Grid item>
                        <Icon
                          style={{ fontSize: 24 }}
                          className={tour.data.category.document.data.icon}
                        />
                        {tour.data.category.document.data.title.text}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardContent className={classes.content}>
                    <Typography
                      component={Link}
                      className={classes.title}
                      to={`/${localizedSlugPrefix(tour)}/${pageSlug}/${
                        tour.uid
                      }/`}
                    >
                      {tour.data.title.text}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.intro}
                    >
                      {tour.data.introduction.text}
                    </Typography>

                    <Grid container className={classes.summary}>
                      <Grid item xs={6} className={classes.summaryBox}>
                        <Icon
                          className="icon_set_1_icon-83"
                          style={{ fontSize: 12 }}
                        />
                        &nbsp;{tour.data.duration / 60} {i18n.hours}
                      </Grid>
                      <Grid item xs={6} className={classes.summaryBox}>
                        <Icon
                          className="icon_set_1_icon-30"
                          style={{ fontSize: 12 }}
                        />
                        &nbsp;{tour.data.services.length} {i18n.activities}
                      </Grid>
                      <Grid item xs={6} className={classes.summaryBox}>
                        <Icon
                          className="icon_set_1_icon-58"
                          style={{ fontSize: 12 }}
                        />
                        &nbsp;{i18n.traditionalLunch}
                      </Grid>
                      <Grid item xs={6} className={classes.summaryBox}>
                        <Icon
                          className="icon_set_1_icon-29"
                          style={{ fontSize: 12 }}
                        />
                        &nbsp;{i18n.tourGuide}
                      </Grid>
                      <Grid item xs={6} className={classes.summaryBox}>
                        <Icon
                          className="icon_set_1_icon-15"
                          style={{ fontSize: 12 }}
                        />
                        &nbsp;{i18n.wineTasting}
                      </Grid>
                      <Grid item xs={6} className={classes.summaryBox}>
                        <Icon
                          className="icon_set_1_icon-25"
                          style={{ fontSize: 12 }}
                        />
                        &nbsp;{i18n.includedTransportation}
                      </Grid>
                      <Grid item xs={12} className={classes.summaryBox}>
                        <Icon
                          className="icon_set_1_icon-41"
                          style={{ fontSize: 12 }}
                        />
                        &nbsp;{getLocations(tour)}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Grid
                    direction="column"
                    container
                    alignItems="center"
                    justify="center"
                    className={classes.buttons}
                    spacing={2}
                  >
                    <Grid item>
                      <Typography component="span" className={classes.price}>
                        {tour.data.prices[0].amount}&nbsp;
                        {tour.data.prices[0].currency}
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.priceInfo}
                      >
                        {i18n.fromPerson}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className={classes.button}
                        href="#contained-buttons"
                        component={Link}
                        to={`/${localizedSlugPrefix(tour)}/${pageSlug}/${
                          tour.uid
                        }/`}
                      >
                        {i18n.details}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default TourList

TourList.propTypes = {
  tours: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  pageSlug: PropTypes.string.isRequired,
}
