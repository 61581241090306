import React from "react"
import { navigate, Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"
import {
  Avatar,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
} from "@material-ui/core"

import ArrowRightIcon from "@material-ui/icons/ArrowRight"

import { LocaleContext } from "../../contexts/LocaleContext"

const useStyles = makeStyles(theme => ({
  menu: {
    width: "100%",
  },

  menuIcon: {
    color: "#111",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
}))

const Menu = ({ categories, pageSlug, slug }) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const data = useStaticQuery(query)

  const handleChangeCategory = e => {
    if (e.target.value === "/") {
      navigate(`/${i18n.path}/${pageSlug}/`)
    } else {
      navigate(`/${i18n.path}/${pageSlug}/${e.target.value}/`)
    }
  }

  return (
    <>
      <Hidden mdUp implementation="css">
        <TextField
          select
          fullWidth
          value={slug}
          label="Category"
          onChange={handleChangeCategory}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          <option value="/">{i18n.all}</option>
          {categories.map(category => (
            <option key={category.id} value={category.uid}>
              {category.data.title.text}
            </option>
          ))}
        </TextField>
      </Hidden>

      <Hidden smDown implementation="css">
        <Paper>
          <List className={classes.menu} disablePadding>
            <ListItem
              button
              className={classes.link}
              component={Link}
              to={`/${i18n.path}/${pageSlug}/`}
            >
              <ListItemAvatar>
                <Avatar
                  alt="Transylvanian Castle"
                  src={data.allToursImage.childImageSharp.fixed.src}
                ></Avatar>
              </ListItemAvatar>
              <ListItemText primary={i18n.all} />
              <ArrowRightIcon className={classes.menuIcon} />
            </ListItem>
            <Divider />
            {categories.map((category, index) => (
              <div key={category.id}>
                <ListItem
                  button
                  className={classes.link}
                  component={Link}
                  to={`/${i18n.path}/${pageSlug}/${category.uid}/`}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={category.data.image.alt}
                      src={category.data.image.fluid.src}
                    ></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={category.data.title.text} />
                  <ArrowRightIcon className={classes.menuIcon} />
                </ListItem>
                {index + 1 < categories.length && <Divider />}
              </div>
            ))}
          </List>
        </Paper>
      </Hidden>
    </>
  )
}

const query = graphql`
  query ToursMenuQuery {
    allToursImage: file(relativePath: { eq: "all_tours.jpg" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default Menu

Menu.propTypes = {
  pageSlug: PropTypes.string.isRequired,
  categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  slug: PropTypes.string.isRequired,
}
