import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Grid, Typography } from "@material-ui/core"

import Breadcrumbs from "../components/Breadcrumbs"
import Menu from "../components/tours/Menu"
import TourList from "../components/tours/TourList"
import NeedHelpBlock from "../components/NeedHelpBlock"
import SEO from "../components/SEO"
import { localizedSlugPrefix } from "../gatsby/nodeHelpers"

const useStyles = makeStyles(theme => ({
  topWrapper: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 110px)",
    },
  },
  mainImage: {
    height: "100%",
  },
  topOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    top: 0,
    left: 0,
  },

  pageTitle: {
    fontSize: 48,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "center",
  },

  pageSlogan: {
    color: theme.palette.common.white,
    fontSize: 20,
    textAlign: "center",
  },

  container: {
    marginTop: 30,
  },
}))

const ToursCategory = ({
  data: { tourCategories, tours, toursCategory },
  pageContext: { locale, slug, pageSlug, breadcrumbs, alternateLanguages },
  location,
}) => {
  const classes = useStyles()

  const categories = tourCategories.edges.map(r => r.node)
  const tourList = tours.edges.map(r => r.node)

  const meta = {
    title: toursCategory.data.meta_title,
    description: toursCategory.data.meta_description,
    keywords: toursCategory.data.meta_keywords,
    author: "Turist in Transilvania",
  }

  const schema = toursCategory.data.schema_json.text || ""
  const ogp = toursCategory.data.ogp_tags || []

  ogp.push({
    property: "og:image",
    content: toursCategory.data.image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: toursCategory.data.image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: toursCategory.data.image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: toursCategory.data.image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const parsedSchema = JSON.parse(schema)

  parsedSchema["@graph"].push({
    "@type": "ItemList",
    "@context": "https://schema.org/",
    itemListElement: tourList.map((tour, index) => ({
      "@type": "ListItem",
      name: tour.data.title.text,
      position: index + 1,
      item: null,
      url: `${process.env.GATSBY_BASE_URL}/${localizedSlugPrefix(
        tour
      )}/${slug}/${tour.uid}`,
    })),
    name: toursCategory.data.title.text,
    description: toursCategory.data.slogan.text,
    itemListOrder: "http://schema.org/ItemListOrderAscending",
  })

  return (
    <>
      <SEO
        schema={JSON.stringify(parsedSchema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={alternateLanguages}
      />
      <div className={classes.topWrapper}>
        <Img
          alt={toursCategory.data.image.alt}
          className={classes.mainImage}
          fluid={toursCategory.data.image.fluid}
        />
        <Grid
          direction="row"
          justify="center"
          alignItems="center"
          container
          className={classes.topOverlay}
        >
          <Grid item xs={12}>
            <Typography component="h1" className={classes.pageTitle}>
              {toursCategory.data.title.text}
            </Typography>

            <Typography component="h2" className={classes.pageSlogan}>
              {toursCategory.data.slogan.text}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Container maxWidth="lg" className={classes.container}>
        <main>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Menu categories={categories} slug={slug} pageSlug={pageSlug} />
              <br />
              <br />
              <NeedHelpBlock />
            </Grid>
            <Grid item xs={12} md={9}>
              <TourList tours={tourList} pageSlug={pageSlug} />
            </Grid>
          </Grid>
        </main>
      </Container>
    </>
  )
}

export default ToursCategory

export const query = graphql`
  query ToursCategoryQuery($slug: String!, $locale: String!) {
    toursCategory: prismicTourCategory(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        meta_title
        meta_description
        meta_keywords
        image {
          alt
          dimensions {
            height
            width
          }

          fluid(maxWidth: 1980, maxHeight: 1070) {
            ...GatsbyPrismicImageFluid
          }
        }
        slogan {
          text
        }
        title {
          text
        }
      }
    }

    tourCategories: allPrismicTourCategory(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          uid
          lang
          data {
            title {
              text
            }
            icon
            image {
              alt
              fluid(maxWidth: 90) {
                src
              }
            }
          }
        }
      }
    }

    tours: allPrismicTour(
      filter: {
        lang: { eq: $locale }
        data: { category: { uid: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          id
          uid
          lang
          data {
            title {
              text
            }
            introduction {
              text
            }
            main_image {
              alt
              fluid(maxHeight: 220) {
                ...GatsbyPrismicImageFluid
              }
            }
            prices {
              amount
              currency
            }
            tour_guide
            traditional_lunch
            transportation
            wine_tasting
            duration
            services {
              service_link {
                document {
                  ... on PrismicService {
                    data {
                      address
                    }
                  }
                }
              }
            }
            category {
              document {
                ... on PrismicTourCategory {
                  lang
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

ToursCategory.propTypes = {
  data: PropTypes.shape({
    tourCategories: PropTypes.object.isRequired,
    tours: PropTypes.object.isRequired,
    toursCategory: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    pageSlug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
